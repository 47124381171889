.bg-header {
  position: absolute;
  background-image: url(../assets/images/bg-header.png);
  width: 100%;
  top: 0;
  background-size: auto;
  height: 150px;
  z-index: -1;
}

.card-rounded {
  border-radius: 20px !important;
}

a.c-sidebar-nav-link.c-active {
  background-color: #321fdb !important;
}

a.c-sidebar-nav-link.c-active:hover {
  background-color: #321fdb !important;
}

a.c-sidebar-nav-link:hover {
  background-color: grey !important;
}

div.fr-wrapper > div > a {
  /* display: none !important; */
  /* position: fixed; */
  /* z-index: -99999 !important; */
  font-size: 0px !important;
  padding: 0px !important;
  height: 0px !important;
}

.CalendarDay__selected_span {
  background: #321fdb;
  color: white;
  border: 1px solid silver;
}
.CalendarDay__selected {
  background: #321fdb;
  color: white;
}

/* ------------ soal navigation ------------ */
.soal-item {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
}

.soal-item-container > div {
  margin: 10px;
}

/* ------------ box soal ------------ */
.box-soal {
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-top: 4px solid #ddd;
  border-bottom: 4px solid #ddd;
}

.bg-black {
  background-color: #000 !important;
}

.answer-wrapper,.answer-wrapper-unanswered {
  position: absolute;
  right: -10px;
  top: -12px;
  z-index: 20;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.answer-wrapper {
  border: 1px solid #000;
  background-color: #fff;
}

.answer-wrapper-unanswered {
  border: 1px solid #ced2d8;
  background-color: #ced2d8;
}

#raguRagu {
  border: #fff;
}

.border-gray {
  border: 2px solid #ced2d8;
}
